import React from "react"
import { injectIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = ({ intl }) => (
  <Layout>
    <SEO
      title={intl.formatMessage({ id: "nav.alt.about" })}
      keywords={[`Hear The Blind Spot`, `About`, `Together!`]}
    />
    <div className="container flex flex-wrap w-full">
      <p className="headingText w-2/3 mt-10">
        {intl.formatMessage({ id: "about.together" })}
      </p>
      <p className="bodyText">{intl.formatMessage({ id: "about.p1" })}</p>
      <p className="bodyText">{intl.formatMessage({ id: "about.p2" })}</p>

      <p className="headingText w-2/3 mt-10">
        {intl.formatMessage({ id: "about.htbs.title" })}
      </p>

      <p className="bodyText">{intl.formatMessage({ id: "about.htbs.p1" })}</p>
      <p className="bodyText">{intl.formatMessage({ id: "about.htbs.p2" })}</p>
      <ul className="list-disc py-2 ml-4 mt-2">
        <li className="bodyText">
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
            href={intl.formatMessage({ id: "about.htbs.bullet1-link" })}
          >
            {intl.formatMessage({ id: "about.htbs.bullet1" })}
          </a>
        </li>
        <li className="bodyText">
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
            href={intl.formatMessage({ id: "about.htbs.bullet2-link" })}
          >
            {intl.formatMessage({ id: "about.htbs.bullet2" })}
          </a>
        </li>
        <li className="bodyText">
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
            href={intl.formatMessage({ id: "about.htbs.bullet3-link" })}
          >
            {intl.formatMessage({ id: "about.htbs.bullet3" })}
          </a>
        </li>
        <li className="bodyText">
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
            href={intl.formatMessage({ id: "about.htbs.bullet4-link" })}
          >
            {intl.formatMessage({ id: "about.htbs.bullet4" })}
          </a>
        </li>
      </ul>
    </div>
  </Layout>
)

export default injectIntl(AboutPage)
